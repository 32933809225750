@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";

footer {
  min-height: 30vh;
  background-color: $dk_light_black;
  color: $dk_white;

  .container {
    padding: 12vh 0;
  }
  .row {
    align-content: center;

    div {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }

  .footer--sitemap {
    list-style: none;
    margin: revert;

    li {
      padding: 1.5% 0;
    }

    .footer--sitemap__link {
      text-decoration: none !important; /* no underline */
      color: white; /* blue colors for links too */
    }
  }

  .footer--contact {
    .footer--contact__socials {
      filter: invert(100%) sepia(58%) saturate(0%) hue-rotate(359deg)
        brightness(106%) contrast(101%);
      margin-top: 30%;
      a {
        width: 3vw;
      }
    }
  }

  .footer--logo {
    text-align: right;

    .footer--backtotop {
      cursor: pointer;
    }

    .footer--backtotop__icon {
      width: 1rem;
      margin-left: 1vw;
    }

    .footer--logo__icon {
      width: 30%;
      margin: auto;
    }
  }
}

@media screen and (max-width: $large) {
  footer {
    min-height: 20rem;
    height: 12vh;
    background-color: $dk_light_black;
    color: $dk_white;
    position: relative;
    overflow: hidden;

    .footer--sitemap {
      list-style: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 25%;

      margin-left: auto;
      margin-right: auto;
      padding: 0;

      li {
        padding: 1.5% 0;
        text-align: center;
        h5 {
          font-size: 2rem;
        }
      }

      .footer--sitemap__link {
        text-decoration: none !important; /* no underline */
        color: white; /* blue colors for links too */
      }
    }

    .footer--contact {
      position: absolute;
      padding: 0;
      margin: 0;
      bottom: 14%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      .footer--contact__address {
        display: none;
      }
      .footer--contact__socials {
        position: absolute !important;
        padding: 0;
        margin: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        a {
          width: 3vw;
          padding: 10%;
          margin: 0;

          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .footer--logo {
      text-align: center;

      .footer--backtotop {
        display: none;
      }

      .footer--backtotop__icon {
        display: none;
      }

      .footer--email {
        position: absolute;
        top: 80%;
        left: 0;
        right: 0;

        margin-left: auto;
        margin-right: auto;

        h3 {
          font-size: 2rem;
          padding: 0;
          margin: 0;
        }
      }

      .footer--logo__icon {
        width: 10vw;
        min-width: 5rem;
        position: absolute;
        padding-top: 0%;
        left: 0%;
        right: 0;
        top: 10%;

        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media screen and (min-height: $large) {
  footer {
    min-height: auto;
  }
}
