@import "core/styles/colours";

.campaigns {
  .navbar {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
}

.campaigns-history,
.campaigns-graphs {
  display: flex;
  position: relative;
  flex-flow: wrap;
  gap: 4rem 2rem;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  flex-basis: 100%;
}



.campaigns-db {
  .listitem {
    .listitem__title {
      flex-basis: calc(30% - 1rem) !important;
    }

    .listitem__value {
      flex-basis: calc(25% - 1rem) !important;
      text-align: start !important;
    }

    .listitem__icon {
      flex-basis: calc(10% - 1rem) !important;
      min-height: 80px;
    }
    .listitem__header {
      padding: 1rem !important;

      &:hover {
        color: $dk_light_black;

        .listitem__title {
          text-decoration: underline;
        }
      }
    }
  }

  .simple {
    .listitem {
      .listitem__header {
        &:hover {
          color: $dk_light_black;
          cursor: default;

          .listitem__title {
            text-decoration: none;
          }
        }

        .listitem__value {
          text-align: end !important;
        }
      }
    }
  }
  
  a {
    color: $dk_light_black;
    width: 100%;
  }
}

.campaign-config {
  display: flex;
  position: relative;
  flex-flow: wrap;
  gap: 4rem 2rem;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  flex-basis: 100%;
}