// Slick Carousel
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// React-Big-Calendar
@import 'react-big-calendar/lib/sass/styles';

// Core
@import "core/styles/buttons";
@import "core/styles/colours";
@import "core/styles/forms";
@import "core/styles/screen-sizes";
@import "core/styles/layouts";
@import "core/styles/typography";


body {
  font-family: "Raleway", sans-serif;
  color: $dk_light_black;
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1400px;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1600px;
  }
}

@media (min-width: 2000px) {
  .container {
    max-width: 1800px;
  }
}

@media (min-width: 2200px) {
  .container {
    max-width: 2000px;
  }
}

@media (min-width: 2500px) {
  .container {
    max-width: 2300px;
  }
}

@media (min-width: 2800px) {
  .container {
    max-width: 70vw;
  }
}

.dk--divider {
  width: 5%;
  background-color: $dk_red; /* Modern Browsers */

  color: $dk_red;
  margin: auto;
  border: 1.5px solid;
  border-color: $dk_red;
}
