@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";
@import "src/core/styles/layouts";

.tile.wide.list {
  padding: 2rem;

  .list__title__container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.expanded {
      padding-bottom: 1rem;
    }

    &.collapsible {
      &:hover {
        cursor: pointer;
        background-color: $dk_grey_5;
      }
    }
  }
  .list__title {
    text-align: left;
    display: flex;

    & > h2 {
      // margin-bottom: 0;
      margin: auto;
    }

    & > svg {
      width: 2.4rem;
      height: 2.4rem;
      // margin-top: .3rem;
      margin: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .list__subtitle {
    text-align: right;
    margin-top: .6rem;

    & > h5 {
      margin-bottom: 0;
    }
  }

  .tabs-container {
    width: 100%;

    .tabs-header {
      border-bottom: 1px solid;
      border-color: rgba(0, 0, 0, 0.12);

      button {
        font-weight: bold;
        background-color: $dk_grey_4;
      }

      svg {
        margin-left: 8px;
      }
    }
  }

  .tabs-header {
    button {
      &:hover {
        background-color: $dk_grey_3;
        border-bottom: 2px solid $dk_grey_2;
      }

      &:not(:hover) {
        border-bottom: 0;
      }

      &.Mui-selected {
        color: $dk_light_black;
      }
    }

    .MuiTabs-indicator {
      background-color: $dk_light_black;
    }
  }

  .listitem {
    display: flex;
    flex-flow: wrap;
    gap: 0 1rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    border: 1px solid $dk_light_black;
    box-shadow: 4px 4px 4px $dk_grey_3;
    border-radius: 0.5rem;

    &:first-child {
      margin-top: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 2px 2px 4px $dk_grey;
    }

    &.issue {
      &-OK {
        border-left: 8px solid green;
      }

      &-WARNING {
        border-left: 8px solid orange;
      }

      &-ERROR {
        border-left: 8px solid $dk_red;
      }
    }

    .listitem__header {
      display: flex;
      flex-flow: wrap;
      gap: 0 1rem;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      flex-basis: 100%;
      padding: 1rem 1rem 0 1rem;
      border-radius: 0.5rem;

      &.expanded {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0rem;
        border-bottom-right-radius: 0rem;
      }

      &:hover {
        background-color: $dk_grey_5;
      }

      .listitem__title {
        flex-basis: calc(50% - 1rem);
        font-weight: bold;
        font-size: 1.3rem;
      }

      .listitem__subtitle {
        flex-basis: calc(20% - 1rem);
        font-size: 1rem;
      }

      .listitem__value {
        flex-basis: calc(25% - 1rem);
        text-align: end;
        font-weight: bold;
        font-size: 1.1rem;

        &.issue {
          &-OK {
            color: green;
          }

          &-WARNING {
            color: orange;
          }

          &-ERROR {
            color: $dk_red;
          }
        }

        svg {
          margin: 0 1rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .listitem__toggle-details {
        flex-basis: calc(5% - 1rem);

        svg {
          width: 100%;
          height: 100%;
          padding: 20%;
          border-radius: 50%;
        }
      }

      .listitem__separator {
        flex-basis: 100%;
        height: 1px;
        background-color: $dk_grey_3;
        margin: 1rem 0 0 0;
      }
    }

    .listitem__body {
      display: flex;
      flex-flow: wrap;
      gap: 0 1rem;
      align-items: center;
      justify-content: space-between;
      flex-basis: 100%;
      padding: 1rem;
      opacity: 1;
      height: min-content;
      transition: height 0.15s ease-out, margin 0.15s ease-out,
        opacity 0.15s ease-out, padding 0.15s ease-out;

      &.hidden {
        margin-top: 0;
        margin-bottom: 0;
        overflow-y: hidden;
        opacity: 0;
        padding: 0 1rem;
      }

      .listitem__data {
        flex-basis: 90%;
        margin-left: calc(5% + 0.5rem);
        display: flex;
        justify-content: space-between;
        height: 48px;
        opacity: 1;
        transition: height 0.15s ease-out, margin 0.15s ease-out,
          opacity 0.15s ease-out;

        &:first-of-type {
          margin-top: 1rem;
        }

        &:last-of-type {
          margin-bottom: 2rem;
        }

        &-item {
          & > div:first-of-type {
            font-weight: bold;
          }

          @for $i from 1 through 12 {
            &.wd-#{$i} {
              flex-basis: calc(#{$i} * 10%);
            }
          }
        }

        &.hidden {
          height: 0;
          margin-top: 0;
          margin-bottom: 0;
          overflow-y: hidden;
          opacity: 0;
        }
      }
    }
  }
}

.checkmark {
  &.issue {
    &-OK {
      color: green;
    }
    &-WARNING {
      color: orange;
    }
  }
}

@media (max-width: $x-large) {
  .tile.wide.list {
    padding: 1rem;

    .list__title {
      &.expanded {
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: $medium) {
  .tile.wide.list {
    padding: .5rem;

    .list__title {
      &.expanded {
        margin-bottom: 1rem;
      }
    }
  }
}
