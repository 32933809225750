@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";

nav {
  font-size: 0.95vw !important;

  .nav__logo {
    width: 13vw;
    margin: 2.5vw 0vw;
    backdrop-filter: blur(1px);
  }

  .nav__list {
    display: inline-block;
    padding: 0 2.2vw;
  }

  .nav__link {
    color: $dk_grey;
    text-decoration: none;
  }

  .nav__link.btn {
    color: $dk_red;
  }

  .nav__cta {
    padding-right: 0;
    padding-left: 2vw;
  }

  .navbar-expand-lg {
    justify-content: space-between;
    width: 100%;
  }

  .nav__link:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: -5%;
    position: relative;
    background: $dk_red;
    transition: width 0.2s ease 0s, left 0.2s ease 0s;
    width: 0%;
  }
  .nav__link:hover:after {
    width: 110%;
    left: 0;
  }

  .nav__link.btn:hover:after {
    width: 0%;
  }

  .active {
    font-weight: bold;
  }

  .active:after {
    background: $dk_red;
    bottom: 0;
    content: "";
    display: block;
    width: 110%;
  }
}


.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler {
  padding: 0;
  z-index: 10;
  position: fixed;
  top: 3%;

  #nav-icon {
    width: 30px;
    height: calc(2.5rem + 5vw);
    position: fixed;
    right: 7%;
    margin: 0 auto;
    transform: rotate(0deg);
    cursor: pointer;
    backdrop-filter: blur(1px);
    top: 0.5%;

    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 60%;
      background: $dk_grey;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      border-radius: 40px;
    }
  }

  &[aria-expanded="false"] {
    #nav-icon {
      span:nth-child(even) {
        left: 50%;
      }
      span:nth-child(odd) {
        left: 0px;
      }
      span:nth-child(1),
      span:nth-child(2) {
        top: calc(50% - 9px);
      }
      span:nth-child(3),
      span:nth-child(4) {
        top: 50%;
      }
      span:nth-child(5),
      span:nth-child(6) {
        top: calc(50% + 9px);
      }
    }
  }

  &[aria-expanded="true"] {
    #nav-icon {
      span {
        background: $dk_grey;
      }

      span:nth-child(1),
      span:nth-child(6) {
        transform: rotate(45deg);
        width: 80%;
        top: calc(50% - 7px);
      }
      span:nth-child(2),
      span:nth-child(5) {
        transform: rotate(-45deg);
        width: 80%;
        top: calc(50% - 7px);
      }
      span:nth-child(1) {
        left: 5px;
        top: calc(50%);
      }
      span:nth-child(2) {
        left: calc(50% - 5px);
        top: calc(50%);
      }
      span:nth-child(3) {
        left: -50%;
        opacity: 0;
        top: calc(50% - 14px);
      }
      span:nth-child(4) {
        left: 100%;
        opacity: 0;
        top: calc(50% - 14px);
      }
      span:nth-child(5) {
        left: 5px;
        top: calc(50% + 5px);
      }
      span:nth-child(6) {
        left: calc(50% - 5px);
        top: calc(50% + 5px);
      }
    }
  }
}

@media screen and (min-width: $large) {
  .navbar-expand-lg {
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: $large) {
  .navbar {
    height: calc(2.5rem + 5vw);
    margin-bottom: 2rem;
  }

  .nav__link.btn:hover:after {
    width: 100% !important;
  }
  .nav__cta .btn--red:hover {
    background: white !important;
  }

  .nav__logo {
    width: 10rem !important;
    position: fixed !important;
    top: 0.5%;
    z-index: 10;
  }

  .nav__link.btn {
    color: $dk_grey !important;
    box-shadow: none;
    border: none;
  }

  .active:after {
    width: 0% !important;
  }

  .navbar-toggler {
    display: block !important;
  }

  .navbar-collapse {
    background: $dk_white;
    position: fixed;
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: all 0.1s;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0 !important;

      li {
        opacity: 0;
        transition: opacity 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
        a {
          color: $dk_grey;
          font-size: 40px;
          padding: 0;
          font-weight: 800;
        }
        .btn {
          font-size: 40px;
        }
      }
    }

    &.show {
      min-height: 100vh;
      visibility: visible;
      opacity: 1;
      z-index: 9;
      ul {
        li {
          opacity: 1;
          &:nth-child(1) {
            transition-delay: 0.06s;
          }
          &:nth-child(2) {
            transition-delay: 0.12s;
          }
          &:nth-child(3) {
            transition-delay: 0.18s;
          }
          &:nth-child(4) {
            transition-delay: 0.24s;
          }
          &:nth-child(5) {
            transition-delay: 0.3s;
          }
          &:nth-child(6) {
            transition-delay: 0.36s;
          }
        }
      }
    }
  }
}