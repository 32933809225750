@import "core/styles/colours";

.reports {
  .navbar {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
}

.reports-history {
  display: flex;
  position: relative;
  flex-flow: wrap;
  gap: 4rem 2rem;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  flex-basis: 100%;
}

.reports-db {
  .listitem {
    .listitem__title {
      flex-basis: calc(30% - 1rem) !important;
    }

    .listitem__value {
      flex-basis: calc(25% - 1rem) !important;
      text-align: start !important;
    }

    .listitem__icon {
      flex-basis: calc(10% - 1rem) !important;
      min-height: 80px;
    }
    .listitem__header {
      padding: 1rem !important;

      &:hover {
        color: $dk_light_black;

        .listitem__title {
          text-decoration: underline;
        }
      }
    }
  }

  .simple {
    .listitem {
      .listitem__header {
        &:hover {
          color: $dk_light_black;
          cursor: default;

          .listitem__title {
            text-decoration: none;
          }
        }

        .listitem__value {
          text-align: end !important;
        }
      }
    }
  }

  a {
    color: $dk_light_black;
    width: 100%;
  }
}

.days-of-week {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: space-between;
  flex-basis: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-height: 3rem;
  line-height: 3rem;

  .active {
    color: $dk_light_black;
  }
  .inactive {
    color: $dk_grey_3;
    $width: 2%;
    $width-outer: 3 * $width;
    background: linear-gradient(
      to left top,
      transparent 50% - $width-outer,
      currentColor 50% - $width,
      currentColor 50% + $width,
      transparent 50% + $width-outer
    );
  }
}

.report-config {
  display: flex;
  position: relative;
  flex-flow: wrap;
  gap: 4rem 2rem;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  flex-basis: 100%;
}