@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";

.calendar--hero__form {
  & > div > label {
    font-weight: bolder;
    font-size: 1.2rem;
    bottom: -0.5rem;
    position: relative;
  }
}

.calendar--button {
  padding: 0.6rem 2rem;
  text-align: center;
  width: fit-content;
  border-radius: 40px;
  background: #fff;
  border: 1px solid $dk_red;
  color: $dk_red;
  letter-spacing: 1px;
  text-shadow: 0;
  cursor: pointer;
  min-width: 10vw;

  transition: all 0.25s ease;
  &:hover {
    color: white;
    background: $dk_red;
  }
  &:active {
    letter-spacing: 2px;
  }
  &:after {
    content: "Log in";
  }

  &.loading {
    width: 40px;
    border-color: $dk_grey;
    border-width: 3px;
    font-size: 0;
    border-left-color: $dk_red;
    animation: rotating 0.7s 0.25s linear infinite !important;
    min-width: 0vw;
    padding: 0;
    height: 40px;

    &:after {
      content: "";
    }
  }

  &.sent {
    color: $dk_light_black;
    background: white;
    border-radius: 0;
    border-color: $dk_white;
    pointer-events: none;

    &:after {
      content: "Logging in...";
    }
  }
}

.calendar--button {
  &:after {
    content: "Save";
  }

  &.cancel {
    float: right;
    
    &:after {
      content: "Clear";
    }
  }
}

button:disabled {
  cursor: not-allowed;

  &:hover {
    background-color: $dk_red_light;
  }
}


.rbc-month-view {
  min-height: 500px;
}

.tile.calendar {
  width: calc(50% - 1rem);
  height: max-content;

  .tabs-container {
    max-width: 100%;
  }
}

.rbc-calendar {
  .expected {
    color: $dk_grey;
  }

  .received {
    color: green;
  }
}