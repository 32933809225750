@import "core/styles/buttons.scss";
@import "core/styles/colours.scss";

.issues-list {
  .issue {
    .issue__title {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
    .issue__description {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
    .issue__link {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
  }
}

.dashboard {
  .listitem__header {
    .listitem__title {
      flex-basis: calc(50% - 1rem);
    }

    .listitem__subtitle {
      flex-basis: 0% !important;
    }

    .listitem__value {
      flex-basis: calc(45% - 1rem) !important;
    }
  }
}

.btn.resolve {
  padding: 0.6rem 2rem;
  text-align: center;
  width: fit-content;
  border-radius: 40px;
  background: #fff;
  border: 1px solid $dk_red;
  color: $dk_red;
  letter-spacing: 1px;
  text-shadow: 0;
  cursor: pointer;
  min-width: 10vw;
  transition: all 0.25s ease;

  &:hover {
    color: white;
    background: $dk_red;
  }

  &:after {
    content: "Resolve";
  }

  &.resolving {
    width: 40px;
    border-color: $dk_grey;
    border-width: 3px;
    font-size: 0;
    border-left-color: $dk_red;
    animation: rotating 0.7s 0.25s linear infinite !important;
    min-width: 0vw;
    padding: 0;
    height: 40px;

    &:after {
      content: "Resolving...";
    }
  }

  &.resolved {
    color: $dk_light_black;
    background: white;
    border-radius: 0;
    border-color: $dk_white;
    pointer-events: none;

    &:after {
      content: "Resolved";
    }
  }
}
