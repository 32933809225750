@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";

.MuiTableCell-root {
  font-family: "Raleway", sans-serif;
}

thead {
  background-color: $dk_grey_4;
}

.tabs-header {
  margin-bottom: 2rem;
}

.tw-toggle {
  display: inline-block;
  padding: 0px 0px;
  border-radius: 20px;
  position: relative;
  border: 2px solid #95a5a6;
  line-height: 21px;
  width: #{"min(80%, 75px)"};
  height: 25px;

  & > label {
    font-family: sans-serif;
    display: inline-block;
    color: #95a5a6;
    position: absolute;
    z-index: 2;
    text-align: center;
    font-size: 15px;
    left: 0px;
    width: 33%;
    border-radius: 50%;

    &:nth-of-type(2) {
      left: 33%;
    }
    &:nth-of-type(3) {
      left: 67%;
    }
    
    &.selected {
      color: #fff;
    }
    &:hover:not(.selected) {
      background: #dedede;
    }
    
    & > input {
      position: absolute;
      z-index: 3;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      left: 0px;
      margin: 0;
      top: 0;
    }
  }
  & > span {
    height: 100%;
    width: 33%;
    line-height: 21px;
    border-radius: 50%;
    background: #fff;
    display: block;
    position: absolute;
    margin: auto;
    left: 25px;
    top: 0px;
    transition: all 0.3s ease-in-out;
    
    &.yes {
        background: green;
        left: 0;
    }
    &.no {
      background: #c0162e;
      left: 67%;
    }
    &.null {
      background: #95a5a6;
      left: 33%;
    }
  }
}
