@import "./colours";
@import "./screen-sizes";

section {
  display: flex;
  margin: 0 8vw;
  position: relative;
  flex-flow: wrap;
  gap: 4rem 2rem;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.tile {
  color: $dk_light_black;
  border: 1px solid $dk_light_black;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 5px 5px #59595b;
  height: 20rem;
  position: relative;
  flex-basis: calc(50% - 1.1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &.square {
    height: 44rem;
  }

  &.wide {
    flex-basis: 100%;
    height: 30vw;
  }

  &.list {
    height: max-content !important;
  }

  &.banner {
    flex-basis: 100%;
    height: max-content;
  }
}

.tile-container {
  flex-basis: calc(50% - 1.1rem);
  display: flex;
  flex-flow: wrap;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  height: 44rem;
  gap: 2rem;

  .tile {
    &.square {
      flex-basis: calc(50% - 1.1rem);
      height: 21rem;
    }

    &.wide {
      flex-basis: 100%;
      height: 21rem;
    }
  }

  &.grow {
    height: min-content;

    .tile {
      &.square {
        height: min-content;
      }

      &.wide {
        height: min-content;
      }
    }
  }

  &.wide {
    flex-basis: 100%;
  }
}

.mini-tile-container {
  flex-basis: 100%;
  display: flex;
  flex-flow: wrap;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  height: 22rem;
  gap: 2rem;

  .tile {
    &.square {
      flex-basis: calc(25% - 1.67rem);
      height: 21rem;
    }

    &.wide {
      flex-basis: calc(50% - 1rem);
      height: 21rem;
    }
  }
}

@media (max-width: $large) {
  .tile.wide {
    flex-basis: 100%;
  }
  .tile-container {
    flex-basis: 100%;
    
    .tile.wide {
      flex-basis: 100%;
    }
    &.wide {
      flex-basis: 100%;
    }
  }
}
