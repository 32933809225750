@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";

section.login--hero {
  min-height: 90vh;
  background-image: url("./images/bg_hero.jpg");
  background-repeat: no-repeat;
  margin-bottom: 20vh;

  .hero__heading {
    padding-top: 6.5rem;
  }

  .hero__subheading {
    margin-top: 2rem;
    border-left: 1px solid $dk_red;

    h6 {
      padding: 0.3rem 1.5rem;
      padding-right: 12rem;
    }
  }

  .login--hero__form {
    padding-top: 10%;

    & > div > label {
      font-weight: bolder;
      font-size: 1.2rem;
      bottom: -0.5rem;
      position: relative;
    }
  }
}

.login--button {
  padding: 0.6rem 2rem;
  text-align: center;
  width: fit-content;
  border-radius: 40px;
  background: #fff;
  border: 1px solid $dk_red;
  color: $dk_red;
  letter-spacing: 1px;
  text-shadow: 0;
  cursor: pointer;
  min-width: 10vw;
  transition: all 0.25s ease;

  &:hover {
    color: white;
    background: $dk_red;
  }
  
  &:active {
    letter-spacing: 2px;
  }

  &:after {
    content: "Log in";
  }

  &.loading {
    width: 40px;
    border-color: $dk_grey;
    border-width: 3px;
    font-size: 0;
    border-left-color: $dk_red;
    animation: rotating 0.7s 0.25s linear infinite !important;
    min-width: 0vw;
    padding: 0;
    height: 40px;

    &:after {
      content: "";
    }
  }

  &.sent {
    color: $dk_light_black;
    background: white;
    border-radius: 0;
    border-color: $dk_white;
    pointer-events: none;

    &:after {
      content: "Logging in...";
    }
  }

  &.otp {
    &:after {
      content: "Verify";
    }

    &.sent {
      &:after {
        content: "Verifying...";
      }
    }
  }

  &.pre-verify {
    &:after {
      content: "Send Email";
    }

    &.sent {
      &:after {
        content: "Sending...";
      }
    }
  }

  &.reset {
    &:after {
      content: "Reset";
    }

    &.sent {
      &:after {
        content: "Resetting...";
      }
    }
  }

  &.update {
    &:after {
      content: "Update";
    }

    &.sent {
      &:after {
        content: "Updating...";
      }
    }
  }

  &.forgot {
    float: right;

    &:after {
      content: "Forgot password?";
    }

    &.sent {
      &:after {
        content: "";
      }
    }
  }
}

.error,
.message {
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: -5%;
}

.error {
  background-color: $dk_red_light;
  border: 1px solid $dk_red;
  color: $dk_red_dark;
}

.message {
  background-color: $dk_grey_4;
  border: 1px solid $dk_light_black;
  color: $dk_light_black;
}

button:disabled {
  cursor: not-allowed;

  &:hover {
    background-color: $dk_red_light;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $large) {
  section.login--hero {
    .hero__subheading {
      margin-top: 2rem;
      border-left: 1px solid $dk_red;

      h6 {
        padding: 0.3rem 1.5rem;
        padding-right: 0rem;
      }
    }
  }
}

@media screen and (min-height: $large) {
  section.login--hero {
    min-height: auto;
  }
}
