@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";
@import "src/core/styles/layouts";

.tile-container {
  .tile {
    & > .echarts-for-react {
      padding: 1.5rem;
    }
  }
}

.tile {
  & > .echarts-for-react {
    padding: 3rem;
  }
}


#context-menu {
  display: none;
  position: fixed;
  z-index: 10000;
  width: 250px;
  background: #1b1a1a;
  border-radius: 5px;
}

#context-menu .item {
  padding: 8px 10px;
  font-size: 15px;
  color: #eee;
  cursor: pointer;
  border-radius: inherit;
}

#context-menu .item:hover {
  background: #343434;
}

.selected-node {
  width: 100%;
  display: flex;

  &.content {
    display: flex;
    justify-content: space-evenly;

    &.item {
      width: -webkit-fill-available;
      padding: 1rem 2rem;
    }
  }
}