@import "src/core/styles/colours";
@import "src/core/styles/screen-sizes";
@import "src/core/styles/layouts";

.data-feeds {
  .navbar {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
}

.issue {
  color: $dk_red;
}

.checkmark {
  color: green;
}

.row {
  width: 100%;
}

$countdown-size: 3rem;
.countdown {
  display: grid;
  margin: 1em auto;
  width: $countdown-size;
  height: $countdown-size;

  svg {
    grid-column: 1;
    grid-row: 1;
  }

  [r] {
    fill: none;
    stroke: silver;

    + [r] {
      transform: rotate(-90deg);
      stroke-linecap: round;
      stroke: green;
    }
  }

  &.issue-OK {
    [r] {
      + [r] {
        stroke: green;
      }
    }
  }
  &.issue-WARNING {
    [r] {
      + [r] {
        stroke: orange;
      }
    }
  }
  &.issue-ERROR {
    [r] {
      + [r] {
        stroke: $dk_red;
      }
    }
  }

  & > .label {
    margin: auto;
    line-height: $countdown-size;
  }
}

.feeds-db {
  .listitem {
    .listitem__title {
      flex-basis: calc(30% - 1rem) !important;
    }

    .listitem__value {
      flex-basis: calc(25% - 1rem) !important;
      text-align: start !important;
    }

    .listitem__icon {
      flex-basis: calc(10% - 1rem) !important;
      min-height: 80px;
    }
    .listitem__header {
      padding: 1rem !important;

      &:hover {
        color: $dk_light_black;

        .listitem__title {
          text-decoration: underline;
        }
      }
    }
  }

  .simple {
    .listitem {
      .listitem__header {
        &:hover {
          color: $dk_light_black;
          cursor: default;

          .listitem__title {
            text-decoration: none;
          }
        }

        .listitem__value {
          text-align: end !important;
        }
      }
    }
  }

  a {
    color: $dk_light_black;
    width: 100%;
  }
}

.feed-config {
  display: flex;
  position: relative;
  flex-flow: wrap;
  gap: 4rem 2rem;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  flex-basis: 100%;
}
